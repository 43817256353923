<template>
    <nav class="right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto
        md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative
        md:w-w-2/12 z-10" v-bind:style="{ right: toggle_right_Sidebar + 'rem' }">
      <div class="">
        <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
          <div class="righ_side_head_">
            <p class="sidebar-heading">Swab & Surge</p>
          </div>
          <div>
            <svg v-on:click="closeNavigation" class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
              style="text-align: right" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
              <path id="close_2_" data-name="close (2)"
                d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
                transform="translate(0 -0.136)" fill="#fff" />
            </svg>
          </div>
        </div>
      </div>  
      <div v-if="staticGraphProp" class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
        <div v-on:click="toggle_height_handler_slug" class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
          >
          <div>
            <p class="sidebar-label lables_with_ico">
              <img src="../../assets/img/shared_vision.png" alt="" />
              Swab & Surge Input
            </p>
          </div>
          <div class="d-flex self-center">
            <p v-if="!isExpandFeatureSlug"><i class="fas fa-angle-down"></i></p>
            <p v-else><i class="fas fa-angle-up"></i></p>  
          </div>
        </div>
        <div v-bind:style="{ display: isExpandFeatureSlug ? '' : 'none' }" class="expand-right-sidebar-feature-one swab_and_surge">
          <div class="px-2 py-2">
            <div>
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">Trip Depth </p>
                  <!-- <p class="label_heading ">Trip Depth ({{ unitConversion?.trip_depth?.unit && unitConversion?.trip_depth?.unit!=''?unitConversion.trip_depth.unit: 'ft'}})</p> -->
                  <input style="z-index: 9999; width:95%"  type="number" @input="changeTripDepthHandler($event)" :value="trip_Depth"  maxlength="15"  
                    class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color input_widtth_with_unit"  />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.trip_depth?.unit && unitConversion?.trip_depth?.unit!=''?unitConversion.trip_depth.unit: isWellMetrics? manualGraphsUnits?.trip_depth?.metric : manualGraphsUnits?.trip_depth?.imperial" :standaredUnit="manualGraphsUnits?.trip_depth?.standardUnits" :reverse="true" @change_unit_handler="(val)=>changeUnitHandler('trip_depth',val)" />
                </div>
              </div>
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">Trip Depth TVD </p>
                  <!-- <p class="label_heading ">Trip Depth TVD ({{ unitConversion?.trip_depth_tvd?.unit && unitConversion?.trip_depth_tvd?.unit!=''?unitConversion.trip_depth_tvd.unit: 'ft'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeTripDepthTVDHandler($event)" :value="trip_Depth_TVD" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.trip_depth_tvd?.unit && unitConversion?.trip_depth_tvd?.unit!=''?unitConversion.trip_depth_tvd.unit:  isWellMetrics? manualGraphsUnits?.trip_depth_tvd?.metric : manualGraphsUnits?.trip_depth_tvd?.imperial" :standaredUnit="manualGraphsUnits?.trip_depth_tvd?.standardUnits" :reverse="true" @change_unit_handler="(val)=>changeUnitHandler('trip_depth_tvd',val)" />
                </div>
              </div>

              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Trip Depth TVD (ft)</p>
                <input style="z-index: 9999" type="number" @input="changeTripDepthTVDHandler($event)" :value="trip_Depth_TVD" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div> -->
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Trip Mode</p>
                    <select :id="widgetId + '2'" @change="changeTripModeHandler($event)" :value="selected_tripmode"
                      class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 left-0 font-color leading-none " >                       
                        <option v-for="option in options" :key="option.text" :value="option.text">                       
                          {{ option.text }}
                        </option>
                    </select>
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Trip Status</p>
                <select :id="widgetId + '2'" @change="changeTripStatusHandler($event)" :value="selected_tripstatus"
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 left-0 font-color leading-none" >                       
                        <option v-for="tripstatus in tripstatuscod" :key="tripstatus.text" :value="tripstatus.text">                        
                        {{ tripstatus.text }}
                        </option>
                    </select>
              </div>
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">Hole Size </p>
                  <!-- <p class="label_heading ">Hole Size  ({{ unitConversion?.hole_size?.unit && unitConversion?.hole_size?.unit!=''?unitConversion.hole_size.unit: 'Inch'}})</p> -->
                <input style="z-index: 9999 ; width:95% " type="number" @input="changeHoleSizeHandler($event)" :value="hole_size" maxlength="15"
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" /> 
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :category="'Length'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.hole_size?.unit && unitConversion?.hole_size?.unit!=''?unitConversion.hole_size.unit: isWellMetrics? manualGraphsUnits?.hole_size?.metric : manualGraphsUnits?.hole_size?.imperial" :standaredUnit="manualGraphsUnits?.hole_size?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('hole_size',val)"  />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Hole Size (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeHoleSizeHandler($event)" :value="hole_size" maxlength="15"
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">DC1 OD </p>
                  <!-- <p class="label_heading ">DC1 OD ({{ unitConversion?.dc1_od?.unit && unitConversion?.dc1_od?.unit!=''?unitConversion.dc1_od.unit: 'Inch'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeDC1ODHandler($event)" :value="dc1_od" maxlength="15" 
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :category="'Length'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.dc1_od?.unit && unitConversion?.dc1_od?.unit!=''?unitConversion.dc1_od.unit: isWellMetrics? manualGraphsUnits?.dc1_od?.metric : manualGraphsUnits?.dc1_od?.imperial" :standaredUnit="manualGraphsUnits?.dc1_od?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('dc1_od',val)" />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC1 OD (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeDC1ODHandler($event)" :value="dc1_od" maxlength="15" 
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">DC1 ID </p>
                  <!-- <p class="label_heading ">DC1 ID ({{ unitConversion?.dc1_id?.unit && unitConversion?.dc1_id?.unit!=''?unitConversion.dc1_id.unit: 'Inch'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeDC1IDHandler($event)" :value="dc1_id" maxlength="15" 
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :category="'Length'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.dc1_id?.unit && unitConversion?.dc1_id?.unit!=''?unitConversion.dc1_id.unit: isWellMetrics? manualGraphsUnits?.dc1_id?.metric : manualGraphsUnits?.dc1_id?.imperial" :standaredUnit="manualGraphsUnits?.dc1_id?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('dc1_id',val)" />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC1 ID (Inch)</p>
                <input style="z-index: 9999; width:95% " type="number" @input="changeDC1IDHandler($event)" :value="dc1_id" maxlength="15" 
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">DC1 Length </p>
                  <!-- <p class="label_heading ">DC1 Length ({{ unitConversion?.dc1_length?.unit && unitConversion?.dc1_length?.unit!=''?unitConversion.dc1_length.unit: 'ft'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeDC1LengthHandler($event)" :value="dc1_length" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :is-custom-events="true" :selected-val=" unitConversion?.dc1_length?.unit && unitConversion?.dc1_length?.unit!=''?unitConversion.dc1_length.unit:  isWellMetrics? manualGraphsUnits?.dc1_length?.metric : manualGraphsUnits?.dc1_length?.imperial" :standaredUnit="manualGraphsUnits?.dc1_length?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('dc1_length',val)" />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC1 Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="changeDC1LengthHandler($event)" :value="dc1_length" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">DC2 OD</p>
                  <!-- <p class="label_heading ">DC2 OD ({{ unitConversion?.dc2_od?.unit && unitConversion?.dc2_od?.unit!=''?unitConversion.dc2_od.unit: 'Inch'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeDC2ODHandler($event)" :value="dc2_od" maxlength="15" 
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :category="'Length'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.dc2_od?.unit && unitConversion?.dc2_od?.unit!=''?unitConversion.dc2_od.unit: isWellMetrics? manualGraphsUnits?.dc2_od?.metric : manualGraphsUnits?.dc2_od?.imperial" :standaredUnit="manualGraphsUnits?.dc2_od?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('dc2_od',val)" />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC2 OD (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeDC2ODHandler($event)" :value="dc2_od" maxlength="15" 
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">DC2 ID</p>
                  <!-- <p class="label_heading ">DC2 ID ({{ unitConversion?.dc2_id?.unit && unitConversion?.dc2_id?.unit!=''?unitConversion.dc2_id.unit: 'Inch'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeDC2IDHandler($event)" :value="dc2_id" maxlength="15"
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :category="'Length'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.dc2_id?.unit && unitConversion?.dc2_id?.unit!=''?unitConversion.dc2_id.unit: isWellMetrics? manualGraphsUnits?.dc2_id?.metric : manualGraphsUnits?.dc2_id?.imperial" :standaredUnit="manualGraphsUnits?.dc2_id?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('dc2_id',val)" />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC2 ID (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeDC2IDHandler($event)" :value="dc2_id" maxlength="15"
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">DC2 Length </p>
                  <!-- <p class="label_heading ">DC2 Length ({{ unitConversion?.dc2_length?.unit && unitConversion?.dc2_length?.unit!=''?unitConversion.dc2_length.unit: 'ft'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeDC2LengthHandler($event)" :value="dc2_length" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.dc2_length?.unit && unitConversion?.dc2_length?.unit!=''?unitConversion.dc2_length.unit:  isWellMetrics? manualGraphsUnits?.dc2_length?.metric : manualGraphsUnits?.dc2_length?.imperial" :standaredUnit="manualGraphsUnits?.dc2_length?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('dc2_length',val)"  />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC2 Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="changeDC2LengthHandler($event)" :value="dc2_length" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">HWDP OD </p>
                  <!-- <p class="label_heading ">HWDP OD ({{ unitConversion?.hwdp_od?.unit && unitConversion?.hwdp_od?.unit!=''?unitConversion.hwdp_od.unit: 'Inch'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeHWDPODHandler($event)" :value="hwdp_od" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :category="'Length'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.hwdp_od?.unit && unitConversion?.hwdp_od?.unit!=''?unitConversion.hwdp_od.unit: isWellMetrics? manualGraphsUnits?.hwdp_od?.metric : manualGraphsUnits?.hwdp_od?.imperial" :standaredUnit="manualGraphsUnits?.hwdp_od?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('hwdp_od',val)"  />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">HWDP OD (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeHWDPODHandler($event)" :value="hwdp_od" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">HWDP ID </p>
                  <!-- <p class="label_heading ">HWDP ID ({{ unitConversion?.hwdp_id?.unit && unitConversion?.hwdp_id?.unit!=''?unitConversion.hwdp_id.unit: 'Inch'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeHWDPIDHandler($event)" :value="hwdp_id" maxlength="15"          
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :category="'Length'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.hwdp_id?.unit && unitConversion?.hwdp_id?.unit!=''?unitConversion.hwdp_id.unit: isWellMetrics? manualGraphsUnits?.hwdp_id?.metric : manualGraphsUnits?.hwdp_id?.imperial" :standaredUnit="manualGraphsUnits?.hwdp_id?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('hwdp_id',val)"  />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">HWDP ID (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeHWDPIDHandler($event)" :value="hwdp_id" maxlength="15"          
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">HWDP Length</p>
                  <!-- <p class="label_heading ">HWDP Length ({{ unitConversion?.hwdp_length?.unit && unitConversion?.hwdp_length?.unit!=''?unitConversion.hwdp_length.unit: 'ft'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeHWDPLengthHandler($event)" :value="hwdp_length" maxlength="15"
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.hwdp_length?.unit && unitConversion?.hwdp_length?.unit!=''?unitConversion.hwdp_length.unit:  isWellMetrics? manualGraphsUnits?.hwdp_length?.metric : manualGraphsUnits?.hwdp_length?.imperial" :standaredUnit="manualGraphsUnits?.hwdp_length?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('hwdp_length',val)" />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">HWDP Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="changeHWDPLengthHandler($event)" :value="hwdp_length" maxlength="15"
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">DP OD </p>
                  <!-- <p class="label_heading ">DP OD ({{ unitConversion?.dp_od?.unit && unitConversion?.dp_od?.unit!=''?unitConversion.dp_od.unit: 'Inch'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeDPODHandler($event)" :value="dp_od" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"   />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :category="'Length'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.dp_od?.unit && unitConversion?.dp_od?.unit!=''?unitConversion.dp_od.unit: isWellMetrics? manualGraphsUnits?.dp_od?.metric : manualGraphsUnits?.dp_od?.imperial" :standaredUnit="manualGraphsUnits?.dp_od?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('dp_od',val)" />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DP OD (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeDPODHandler($event)" :value="dp_od" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"   />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">DP ID </p>
                  <!-- <p class="label_heading ">DP ID ({{ unitConversion?.dp_id?.unit && unitConversion?.dp_id?.unit!=''?unitConversion.dp_id.unit: 'Inch'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeDPIDHandler($event)" :value="dp_id" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :category="'Length'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.dp_id?.unit && unitConversion?.dp_id?.unit!=''?unitConversion.dp_id.unit: isWellMetrics? manualGraphsUnits?.dp_id?.metric : manualGraphsUnits?.dp_id?.imperial" :standaredUnit="manualGraphsUnits?.dp_id?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('dp_id',val)" />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DP ID (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeDPIDHandler($event)" :value="dp_id" maxlength="15" 
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">Dp Length </p>
                  <!-- <p class="label_heading ">Dp Length ({{ unitConversion?.dp_length?.unit && unitConversion?.dp_length?.unit!=''?unitConversion.dp_length.unit: 'ft'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="changeDPLengthHandler($event)" :value="dp_length" maxlength="15"   
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.dp_length?.unit && unitConversion?.dp_length?.unit!=''?unitConversion.dp_length.unit: isWellMetrics? manualGraphsUnits?.dp_length?.metric : manualGraphsUnits?.dp_length?.imperial" :standaredUnit="manualGraphsUnits?.dp_length?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('dp_length',val)" />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Dp Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="changeDPLengthHandler($event)" :value="dp_length" maxlength="15"   
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <p class="label_heading ">Casing Length</p>
                  <!-- <p class="label_heading ">Casing Length ({{ unitConversion?.casing_length?.unit && unitConversion?.casing_length?.unit!=''?unitConversion.casing_length.unit: 'ft'}})</p> -->
                <input style="z-index: 9999; width:95% " type="number" @input="change_real_time_input($event,'static_casing_length')" :value="static_casing_length"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.casing_length?.unit && unitConversion?.casing_length?.unit!=''?unitConversion.casing_length.unit: isWellMetrics? manualGraphsUnits?.casing_length?.metric : manualGraphsUnits?.casing_length?.imperial" :standaredUnit="manualGraphsUnits?.casing_length?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('casing_length',val)" />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Casing Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'static_casing_length')" :value="static_casing_length"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div> -->
              <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <!-- <p class="label_heading ">Casing Id ({{ unitConversion?.casing_id?.unit && unitConversion?.casing_id?.unit!=''?unitConversion.casing_id.unit: 'Inch'}})</p> -->
                  <p class="label_heading ">Casing Id </p>
                <input style="z-index: 9999; width:95% " type="number" @input="change_real_time_input($event,'static_casing_id')" :value="static_casing_id"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :category="'Length'" :pt="'pt-0'" :is-custom-events="true" :selected-val="unitConversion?.casing_id?.unit && unitConversion?.casing_id?.unit!=''?unitConversion.casing_id.unit: isWellMetrics? manualGraphsUnits?.casing_id?.metric : manualGraphsUnits?.casing_id?.imperial" :standaredUnit="manualGraphsUnits?.casing_id?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('casing_id',val)" />
                </div>
              </div>
              <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Casing Id (Inch)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'static_casing_id')" :value="static_casing_id"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div> -->
            </div>
          </div>
        </div>
        <div v-on:click="toggle_height_handler_mudData" class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
        >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/shared_vision.png" alt="" />
            Mud Data
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureMud"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>

        </div>
      </div>
      <div v-bind:style="{ display: isExpandFeatureMud ? '' : 'none' }" class="expand-right-sidebar-feature-one swab_and_surge">
        <div class="px-2 py-2">
          <div>
            <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <!-- <p class="label_heading ">Mud Density ({{ unitConversion?.mud_density?.unit && unitConversion?.mud_density?.unit!=''?unitConversion.mud_density.unit: 'lb/gal'}})</p> -->
                  <p class="label_heading ">Mud Density </p>
                  <input style="z-index: 9999; width:95% " type="number" @input="changeMudDensityHandler($event)" :value="mud_wght" maxlength="15"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :category="'MW-Density gradients'" :is-custom-events="true" :selected-val="unitConversion?.mud_density?.unit && unitConversion?.mud_density?.unit!=''?unitConversion.mud_density.unit: isWellMetrics? manualGraphsUnits?.mud_density?.metric : manualGraphsUnits?.mud_density?.imperial" :standaredUnit="manualGraphsUnits?.mud_density?.standardUnits" :reverse="true"  @change_unit_handler="(val)=>changeUnitHandler('mud_density',val)" />
                </div>
              </div>
            <!-- <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Mud Density (ppg)</p>
                <input style="z-index: 9999" type="number" @input="changeMudDensityHandler($event)" :value="mud_wght" maxlength="15"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
            </div> -->
            <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">r300</p>
              <input style="z-index: 9999" type="text" @input="changeR300Handler($event)" :value="r300"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color " maxlength="15" />
            </div>
            <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">r600</p>
              <input style="z-index: 9999" type="text" @input="changeR600Handler($event)" :value="r600"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color " maxlength="15" />
            </div>
            <div class="flex gap-2">
                <div class="flex pb-2 items-start flex-col" style="width:70%">
                  <!-- <p class="label_heading ">Plastic Viscosity ({{ unitConversion?.plactic_viscosity?.unit && unitConversion?.plactic_viscosity?.unit!=''?unitConversion.plactic_viscosity.unit: 'centipose'}})</p> -->
                  <p class="label_heading ">Plastic Viscosity</p>
              <input style="z-index: 9999; width:95% " type="text" v-model="plastic_Viscosity"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color opacity-75" maxlength="15" disabled/>
                </div>
                <div style="width:30%">
                  <UnitSelect :isMetrics="isWellMetrics" :display-id="displayId" :widget-id="widgetId" :mnemonic="'dmea'" :pt="'pt-0'" :category="'Viscosity'" :is-custom-events="true" :selected-val="unitConversion?.plactic_viscosity?.unit && unitConversion?.plactic_viscosity?.unit!=''?unitConversion.plactic_viscosity.unit: isWellMetrics? manualGraphsUnits?.plactic_viscosity?.metric : manualGraphsUnits?.plactic_viscosity?.imperial" :standaredUnit="manualGraphsUnits?.plactic_viscosity?.standardUnits" :reverse="true" @change_unit_handler="(val)=>changeUnitHandler('plactic_viscosity',val)" />
                </div>
              </div>
            <!-- <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">Plastic Viscosity (cp)</p>
              <input style="z-index: 9999" type="text" v-model="plastic_Viscosity"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color opacity-75" maxlength="15" disabled/>
            </div> -->
            <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">Yield Point (lb/100ft <sup>2</sup>)</p>
              <input style="z-index: 9999" type="text" v-model="yield_point"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color opacity-75" maxlength="15" disabled/>
            </div>
            <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">Flow Index</p>
              <input style="z-index: 9999" type="text"  v-model="flow_index"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color opacity-75" maxlength="15" disabled/>
            </div>
            <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">Consistency Index</p>
              <input style="z-index: 9999" type="text" v-model="Consistency_index"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color opacity-75" maxlength="15" disabled/>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
    </div>
      <div v-if="realTimeGraphProp" class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
        <div v-on:click="toggle_height_handler_slug" class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
          >
          <div>
            <p class="sidebar-label lables_with_ico">
              <img src="../../assets/img/shared_vision.png" alt="" />
              Swab & Surge Input
            </p>
          </div>
          <div class="d-flex self-center">
            <p v-if="!isExpandFeatureSlug"><i class="fas fa-angle-down"></i></p>
            <p v-else><i class="fas fa-angle-up"></i></p>  
          </div>
        </div>
        <div v-bind:style="{ display: isExpandFeatureSlug ? '' : 'none' }" class="expand-right-sidebar-feature-one swab_and_surge">
          <div class="px-2 py-2">
            <div>
              <!-- <div class="flex items-center justify-between pb-2">
                <p class="label_heading ">Trip Depth</p>
                <input style="z-index: 9999" type="number" @input="changeTripDepthLiveHandler($event)" :value="trip_Depth_Live"  maxlength="15"   
                  class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"  />
              </div> -->
              <!-- <div class="flex items-center justify-between pb-2">
                <p class="label_heading ">Trip Depth TVD</p>
                <input style="z-index: 9999" type="number" @input="changeTripDepthTVDLiveHandler($event)" :value="trip_Depth_TVD_Live" maxlength="15"  
                  class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color" />
              </div> -->
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">HOLE DEPTH (ft)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'dmea_live')" :value="dmea_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">BIT DEPTH (ft)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'dbtm_live')" :value="dbtm_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">TRIP DEPTH TVD (FT)</p>
                <input style="z-index: 9999" type="text" v-model="trip_depth_tvd_Live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color " maxlength="15" />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Trip Speed (ft/min)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'trip_speed_live')" :value="trip_speed_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Trip Mode</p>
                    <select :id="widgetId + '2'" @change="change_real_time_input($event,'selected_tripmode_Live')" :value="selected_tripmode_Live"   
                      class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 left-0 font-color leading-none " >                       
                        <option v-for="option in options" :key="option.text" :value="option.text">                       
                          {{ option.text }}
                        </option>
                    </select>
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Trip Status</p>
                <select :id="widgetId + '2'" @change="change_real_time_input($event,'selected_tripstatus_Live')" :value="selected_tripstatus_Live"   
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 left-0 font-color leading-none" >                       
                        <option v-for="tripstatus in tripstatuscod" :key="tripstatus.text" :value="tripstatus.text">                        
                        {{ tripstatus.text }}
                        </option>
                    </select>
              </div>
              <!-- <div class="flex items-center justify-between pb-2">
                <p class="label_heading ">Hole Size</p>
                <input style="z-index: 9999" type="number" @input="changeHoleSizeLiveHandler($event)" :value="hole_size_Live" maxlength="15"  
                  class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color" />
              </div> -->
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC1 OD (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeDC1ODLiveHandler($event)" :value="dc1_od_Live" maxlength="15"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC1 ID (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeDC1IDLiveHandler($event)" :value="dc1_id_Live" maxlength="15"   
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC1 Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="changeDC1LengthLiveHandler($event)" :value="dc1_length_Live" maxlength="15"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC2 OD (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeDC2ODLiveHandler($event)" :value="dc2_od_Live" maxlength="15"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC2 ID (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeDC2IDLiveHandler($event)" :value="dc2_id_Live" maxlength="15"   
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC2 Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="changeDC2LengthLiveHandler($event)" :value="dc2_length_Live" maxlength="15"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">HWDP OD (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeHWDPODLiveHandler($event)" :value="hwdp_od_Live" maxlength="15"   
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">HWDP ID (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeHWDPIDLiveHandler($event)" :value="hwdp_id_Live" maxlength="15"            
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">HWDP Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="changeHWDPLengthLiveHandler($event)" :value="hwdp_length_Live" maxlength="15"   
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color" />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DP OD (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeDPODLiveHandler($event)" :value="dp_od_Live" maxlength="15"   
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"   />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DP ID (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeDPIDLiveHandler($event)" :value="dp_id_Live" maxlength="15"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Dp Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="changeDPLengthLiveHandler($event)" :value="dp_length_Live" maxlength="15"   
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <!-- <div class="flex items-center justify-between pb-2">
                <p class="label_heading ">Cassing OD</p>
                <input style="z-index: 9999" type="number" @input="changeCassingODHandler($event)" :value="cassing_od" maxlength="15"   
                  class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"   />
              </div> -->
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Casing ID (Inch)</p>
                <input style="z-index: 9999" type="number" @input="changeCassingIDHandler($event)" :value="cassing_id" maxlength="15"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Casing Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="changeCassingLengthHandler($event)" :value="cassing_length" maxlength="15"   
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Bit Size (Inch)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'bit_size_live')" :value="bit_size_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC1 Casing Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'dc1_casing_length_live')" :value="dc1_casing_length_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC1 Open Hole Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'dc1_open_hole_length_live')" :value="dc1_open_hole_length_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC2 Casing Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'dc2_casing_length_live')" :value="dc2_casing_length_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DC2 Open Hole Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'dc2_open_hole_length_live')" :value="dc2_open_hole_length_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DP Casing Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'dp_casing_length_live')" :value="dp_casing_length_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">DP Open Hole Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'dp_open_hole_length_live')" :value="dp_open_hole_length_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <!-- <div class="flex items-center justify-between pb-2">
                <p class="label_heading ">Flow Index</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'flow_index_live')" :value="flow_index_live"  
                  class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"  />
              </div> -->
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">HWDP Casing Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'hwdp_casing_length_live')" :value="hwdp_casing_length_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">HWDP Open Hole Length (ft)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'hwdp_open_hole_length_live')" :value="hwdp_open_hole_length_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Surge Pressure (psi)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'surge_pressure_live')" :value="surge_pressure_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Surge EMW (ppg)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'surge_pressure_in_ppg_live')" :value="surge_pressure_in_ppg_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Swab Pressure (psi)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'swab_pressure_live')" :value="swab_pressure_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
              <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Swab EMW (ppg)</p>
                <input style="z-index: 9999" type="number" @input="change_real_time_input($event,'swab_pressure_in_ppg_live')" :value="swab_pressure_in_ppg_live"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
              </div>
            </div>
          </div>
        </div>
        <div v-on:click="toggle_height_handler_mudData" class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
        >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/shared_vision.png" alt="" />
            Mud Data
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureMud"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>

        </div>
      </div>
      <div v-bind:style="{ display: isExpandFeatureMud ? '' : 'none' }" class="expand-right-sidebar-feature-one swab_and_surge">
        <div class="px-2 py-2">
          <div>
            <div class="flex pb-2 items-start flex-col">
                <p class="label_heading ">Mud Density (ppg)</p>
                <input style="z-index: 9999" type="number" @input="changeMudDensityLiveHandler($event)" :value="mud_wght_Live" maxlength="15"  
                  class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color"  />
            </div>
            <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">r300</p>
              <input style="z-index: 9999" type="text" @input="changeR300LiveHandler($event)" :value="r300_Live"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color " maxlength="15" />
            </div>
            <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">r600</p>
              <input style="z-index: 9999" type="text" @input="changeR600LiveHandler($event)" :value="r600_Live"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color " maxlength="15" />
            </div>
            <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">Plastic Viscosity (cp)</p>
              <input style="z-index: 9999" type="text" v-model="plastic_Viscosity_Live"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color " maxlength="15" />
            </div>
            <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">Yield Point (lb/100ft <sup>2</sup>)</p>
              <input style="z-index: 9999" type="text" v-model="yield_point_Live"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color " maxlength="15" />
            </div>
            <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">Flow Index</p>
              <input style="z-index: 9999" type="text"  :value="flow_index_Live"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color " maxlength="15" />
            </div>
            <div class="flex pb-2 items-start flex-col">
              <p class="label_heading ">Consistency Index</p>
              <input style="z-index: 9999" type="text" v-model="Consistency_index_Live"  
                class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 font-color " maxlength="15" />
            </div>
          </div>
        </div>
      </div>
      </div>
      <div v-if="for_ranges_clicked_graph" class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
        <div v-on:click="range_section_toggler" class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
          >
          <div>
            <p class="sidebar-label lables_with_ico">
              <img src="../../assets/img/shared_vision.png" alt="" />
              {{ graph_name }} PSI Ranges
            </p>
          </div>
          <div class="d-flex self-center">
            <p v-if="!expand_range_section"><i class="fas fa-angle-down"></i></p>
            <p v-else><i class="fas fa-angle-up"></i></p>  
          </div>
        </div>
        <div v-bind:style="{ display: expand_range_section ? '' : 'none' }" class="expand-right-sidebar-feature-one swab_and_surge">
          <div class="px-2 py-2">
            <div class="custom-radio-wrap mb-4">
              <div class="form-group-checkbox">
                <label class="w-100 flex font-thin">
                  <span class="label_heading"> X-Manual Scale </span>
                  <input
                    class="ml-auto leading-tight"
                    type="checkbox"
                    @click="change_input_range_value($event.target.checked, 'xAutoScale')"
                    :checked="xAutoScale"
                  />
                </label>
              </div>
              <div class="flex flex-col mt-3">
                <p class="label_heading">X-Start Range</p>
                <input
                  type="number"
                  class="fields border-0 block font-color"
                   
                  @input="change_input_range_value($event.target.value, 'xRangeStart')"
                  :value="xRangeStart"
                />
              </div>
              <div class="flex flex-col mt-3">
                <p class="label_heading">X-End Range</p>
                <input
                  type="number"
                  class="fields border-0 block font-color"
                   
                  @input="change_input_range_value($event.target.value, 'xRangeEnd')"
                  :value="xRangeEnd"
                />
              </div>

              <div class="form-group-checkbox">
                <label class="w-100 flex font-thin mt-3">
                  <span class="label_heading"> Y-Manual Scale </span>
                  <input
                    class="ml-auto leading-tight"
                    type="checkbox"
                    @click="change_input_range_value($event.target.checked, 'yAutoScale')"
                    :checked="yAutoScale"
                  />
                </label>
              </div>
              <div class="flex flex-col mt-3">
                <p class="label_heading">Y-Start Range</p>
                <input
                  type="number"
                  class="fields border-0 block font-color"
                   
                  @input="change_input_range_value($event.target.value, 'yRangeStart')"
                  :value="yRangeStart"
                />
              </div>
              <div class="flex flex-col mt-3">
                <p class="label_heading">Y-End Range</p>
                <input
                  type="number"
                  class="fields border-0 block font-color"
                   
                  @input="change_input_range_value($event.target.value, 'yRangeEnd')"
                  :value="yRangeEnd"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-on:click="range_section_toggler_ppg" class="flex bg-black justify-between pt-5 pl-5 pb-4"
          :class="darkDark ? ' bg-right_' : 'bg-right_light'">
          <div>
            <p class="sidebar-label lables_with_ico">
              <img src="../../assets/img/shared_vision.png" alt="" />
             {{graph_name}} PPG Ranges
            </p>
          </div>
          <div class="d-flex self-center">
            <p v-if="!expand_range_section_ppg"><i class="fas fa-angle-down"></i></p>
            <p v-else><i class="fas fa-angle-up"></i></p>  
          </div>
        </div>
        <div v-bind:style="{ display: expand_range_section_ppg ? '' : 'none' }" class="expand-right-sidebar-feature-one swab_and_surge">
          <div class="px-2 py-2">
            <div class="custom-radio-wrap mb-4">
              <div class="form-group-checkbox">
                <label class="w-100 flex font-thin">
                  <span class="label_heading"> X-Manual Scale </span>
                  <input
                    class="ml-auto leading-tight"
                    type="checkbox"
                    @click="change_input_range_value($event.target.checked, 'xAutoScalePpg')"
                    :checked="xAutoScalePpg"
                  />
                </label>
              </div>
              <div class="flex flex-col mt-3">
                <p class="label_heading">X-Start Range</p>
                <input
                  type="number"
                  class="fields border-0 block font-color"
                  :class="darkDark ? 'input-bg' : 'input-bg_light'"
                  @input="change_input_range_value($event.target.value, 'xRangeStartPpg')"
                  :value="xRangeStartPpg"
                />
              </div>
              <div class="flex flex-col mt-3">
                <p class="label_heading">X-End Range</p>
                <input
                  type="number"
                  class="fields border-0 block font-color"
                  :class="darkDark ? 'input-bg' : 'input-bg_light'"
                  @input="change_input_range_value($event.target.value, 'xRangeEndPpg')"
                  :value="xRangeEndPpg"
                />
              </div>

              <div class="form-group-checkbox">
                <label class="w-100 flex font-thin mt-3">
                  <span class="label_heading"> Y-Manual Scale </span>
                  <input
                    class="ml-auto leading-tight"
                    type="checkbox"
                    @click="change_input_range_value($event.target.checked, 'yAutoScalePpg')"
                    :checked="yAutoScalePpg"
                  />
                </label>
              </div>
              <div class="flex flex-col mt-3">
                <p class="label_heading">Y-Start Range</p>
                <input
                  type="number"
                  class="fields border-0 block font-color"
                  :class="darkDark ? 'input-bg' : 'input-bg_light'"
                  @input="change_input_range_value($event.target.value, 'yRangeStartPpg')"
                  :value="yRangeStartPpg"
                />
              </div>
              <div class="flex flex-col mt-3">
                <p class="label_heading">Y-End Range</p>
                <input
                  type="number"
                  class="fields border-0 block font-color"
                  :class="darkDark ? 'input-bg' : 'input-bg_light'"
                  @input="change_input_range_value($event.target.value, 'yRangeEndPpg')"
                  :value="yRangeEndPpg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </template>
  
  
  <script>

  import UpDownButtons from "../UpDownButtons/UpDownButtons";
  import UnitSelect from "../Navbars/properties/UnitSelect.vue";
  import swabsurgeMapping from "../Widgets/SwabOrSurge/swabsurgeManualGraphsUnits.json"
  
  
  export default {
    name: "swabsurge-properties",
    emits: ['getSideBarData'],
    components: {
      UpDownButtons,
      UnitSelect
    },
    props: {
      displayId: String,
      widgetId: String,
      toggle_right_Sidebar: {
        type: String,
        default: "",
      },
      toggle_right_margin: {
        type: String,
        default: "",
      },
      id: [Number, String],
      properties: {
        default: {},
      },
      closeNavigation: Function,
      clicked_graph: {
            type: [Number, String]
        },
    },
    data() {
      return {
        isExpandFeatureOne: true,
        isExpandFeatureTwo: false,
        isExpandFeatureThree: false,
        isExpandFeatureFour: false,
        isExpandFeatureFive: false,
        isExpandFeatureSix: false,
        isExpandFeatureMud: false,
        expand_range_section: true,
        expand_range_section_ppg: true,
        // isExpandFeatureBHA: false,
        isExpandFeatureSlug: false,
        rows: [],
        idx: -1,
        // selected_tripmode: 'OpenEnd',
        options: [
                { text: 'OPENEND' },
                { text: 'CLOSEEND' },
            ],
        // selected_tripstatus : 'TIH',
        tripstatuscod: [
                { text: 'TIH' },
                { text: 'TOOH' },
            ],
        // title: this.properties.title,
        flow_index :'',
        Consistency_index:'' ,
        plastic_Viscosity:'',
        yield_point: '',
        flow_index_Live :'',
        Consistency_index_Live:'' ,
        plastic_Viscosity_Live:'',
        staticGraphProp:true,
        realTimeGraphProp:true,
        realTimeGraphRanges: true,
        isWellMetrics:false,
        manualGraphsUnits: swabsurgeMapping,
        for_ranges_clicked_graph: '',
      };
    },
    methods: {
      changeUnitHandler(key,factor){
        const fact= {unit:factor.to_unit,conversion_factor:factor.conversion_factor};
        let valKey = key;
        if(valKey=='trip_depth'){
          valKey='trip_Depth';
        }else if(valKey == 'trip_depth_tvd'){
          valKey='trip_Depth_TVD';
        }else if(valKey == 'casing_length'){
          valKey='static_casing_length';
        }else if(valKey == 'casing_id'){
          valKey='static_casing_id';
        }else if(valKey == 'mud_density'){
          valKey='mud_wght';
        }else if(valKey == 'plactic_viscosity'){
          valKey='plastic_Viscosity';
        }else{
          valKey = key;
        }
        // const tempval = this.$store.state.rect.rects[this.idx][valKey];
        // this.$store.state.rect.rects[this.idx][valKey] = Number(tempval) * factor.conversion_factor;
        this.$store.dispatch("rect/changSwabSearchUnit", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          key: key,
          value: fact,
        });

      },
      change_input_range_value(val, key) {
        let ranges_data = {...this.$store.state.rect.rects[this.idx][this.for_ranges_clicked_graph]}
        ranges_data[key] = val
        this.$store.dispatch("rect/swabSurgeRealTimedata", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: ranges_data,
          field: this.for_ranges_clicked_graph,
        });
      },
      // For Static Start
      changeTripDepthHandler(e) {
          this.$store.dispatch("rect/changeTripeDepth", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
        this.total_of_lengths()   
      },
      changeTripDepthTVDHandler(e) {
          this.$store.dispatch("rect/changeTripeDepthTVD", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeHoleSizeHandler(e) {
          this.$store.dispatch("rect/changeHoleSize", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDC1ODHandler(e) {
          this.$store.dispatch("rect/changeDC1OD", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDC1IDHandler(e) {
          this.$store.dispatch("rect/changeDC1ID", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDC1LengthHandler(e) {
          this.$store.dispatch("rect/changeDC1Length", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
        this.total_of_lengths()   
      },
      changeDC2ODHandler(e) {
          this.$store.dispatch("rect/changeDC2OD", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDC2IDHandler(e) {
          this.$store.dispatch("rect/changeDC2ID", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDC2LengthHandler(e) {
          this.$store.dispatch("rect/changeDC2Length", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });     
        this.total_of_lengths()   
      },
      changeHWDPODHandler(e) {
          this.$store.dispatch("rect/changeHWDPOD", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeHWDPIDHandler(e) {
          this.$store.dispatch("rect/changeHWDPID", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeHWDPLengthHandler(e) {
          this.$store.dispatch("rect/changeHWDPLength", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
        this.total_of_lengths()   
      },
      changeDPODHandler(e) {
          this.$store.dispatch("rect/changeDPOD", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDPIDHandler(e) {
          this.$store.dispatch("rect/changeDPID", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDPLengthHandler(e) {
          this.$store.dispatch("rect/changeDPLength", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
        this.total_of_lengths()
      },
      changeMudDensityHandler(e) {
          this.$store.dispatch("rect/changeMudDensity", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeR300Handler(e) {
          this.$store.dispatch("rect/changeR300", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });  
        this.flowindex();     
      },
      changeR600Handler(e) {
          this.$store.dispatch("rect/changeR600", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });   
        this.flowindex();     
      },
      changeTripModeHandler(e) {
          this.$store.dispatch("rect/changeTripMode", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeTripStatusHandler(e) {
          this.$store.dispatch("rect/changeTripStatus", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      
      flowindex(){        
        if(this.r300 != '' &&  this.r600 != '') { 
          this.flow_index =  3.32 * Math.log10(this.r600 / this.r300)
          this.plastic_Viscosity = this.r600 - this.r300
          this.yield_point = this.r300 - this.plastic_Viscosity
          this.$store.dispatch("rect/changeFlowIndex", {
            displayId: this.properties.displayId,
            widgetId: this.properties.widgetId,
            value: this.flow_index,
          });   
          this.$store.dispatch("rect/changePlasticViscosity", {
            displayId: this.properties.displayId,
            widgetId: this.properties.widgetId,
            value: this.plastic_Viscosity,
          });     
          this.change_real_time_input({ target:{ value: this.yield_point}}, 'yield_point')
          this.Consistency()
        }
        else{
          this.flow_index = '';
        }
      },
      
      Consistency(){
        if(this.flow_index != ''){
          this.Consistency_index = (this.r300/Math.pow(511, this.flow_index))
          this.$store.dispatch("rect/changeConsistencyIndex", {
            displayId: this.properties.displayId,
            widgetId: this.properties.widgetId,
            value: this.Consistency_index,
          });   
        }
        else{
          this.Consistency_index = '';
        }
      }, 
       
      toggleAutoHandler() {
        this.$store.dispatch("rect/toggleAutoFit", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
        });
      },
      toggleBorderVisibiltyHandler() {
        this.$store.dispatch("rect/toggleBorderVisibility", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
        });
      },
  
      toggleTitleVisibiltyHandler() {
        this.$store.dispatch("rect/toggleTitleVisibility", {
          displayId: this.displayId,
          id: this.id,
        });
      },
  
      fontValueHandler(e) {
        this.$store.dispatch("rect/titleFontValue", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value ? parseInt(e.target.value) : 0,
        });
      },
  
      toggle_height_handler_one() {
        if (this.isExpandFeatureOne == true) {
          this.isExpandFeatureOne = false;
        } else {
          this.isExpandFeatureOne = true;
        }
      },
      toggle_height_handler_two() {
        if (this.isExpandFeatureTwo == true) {
          this.isExpandFeatureTwo = false;
        } else {
          this.isExpandFeatureTwo = true;
        }
      },
      toggle_height_handler_three() {
        if (this.isExpandFeatureThree == true) {
          this.isExpandFeatureThree = false;
        } else {
          this.isExpandFeatureThree = true;
        }
      },
  
      toggle_height_handler_mudData() {
        if (this.isExpandFeatureMud == true) {
          this.isExpandFeatureMud = false;
        } else {
          this.isExpandFeatureMud = true;
        }
      },
      toggle_height_handler_slug() {
        if (this.isExpandFeatureSlug == true) {
          this.isExpandFeatureSlug = false;
        } else {
          this.isExpandFeatureSlug = true;
        }
      },
      range_section_toggler() {
        this.expand_range_section= !this.expand_range_section
      },
      range_section_toggler_ppg() {
        this.expand_range_section_ppg = !this.expand_range_section_ppg
      },
      
      // For Live Start
      changeTripDepthLiveHandler(e) {
          this.$store.dispatch("rect/changeTripeDepthLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeTripDepthTVDLiveHandler(e) {
          this.$store.dispatch("rect/changeTripeDepthTVDLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeHoleSizeLiveHandler(e) {
          this.$store.dispatch("rect/changeHoleSizeLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDC1ODLiveHandler(e) {
          this.$store.dispatch("rect/changeDC1ODLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDC1IDLiveHandler(e) {
          this.$store.dispatch("rect/changeDC1IDLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDC1LengthLiveHandler(e) {
          this.$store.dispatch("rect/changeDC1LengthLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDC2ODLiveHandler(e) {
          this.$store.dispatch("rect/changeDC2ODLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDC2IDLiveHandler(e) {
          this.$store.dispatch("rect/changeDC2IDLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDC2LengthLiveHandler(e) {
          this.$store.dispatch("rect/changeDC2LengthLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeHWDPODLiveHandler(e) {
          this.$store.dispatch("rect/changeHWDPODLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeHWDPIDLiveHandler(e) {
          this.$store.dispatch("rect/changeHWDPIDLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeHWDPLengthLiveHandler(e) {
          this.$store.dispatch("rect/changeHWDPLengthLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDPODLiveHandler(e) {
          this.$store.dispatch("rect/changeDPODLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDPIDLiveHandler(e) {
          this.$store.dispatch("rect/changeDPIDLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeDPLengthLiveHandler(e) {
          this.$store.dispatch("rect/changeDPLengthLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeCassingODHandler(e) {
          this.$store.dispatch("rect/changeCassingOD", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeCassingIDHandler(e) {
          this.$store.dispatch("rect/changeCassingID", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeCassingLengthHandler(e) {
          this.$store.dispatch("rect/changeCassingLength", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeMudDensityLiveHandler(e) {
          this.$store.dispatch("rect/changeMudDensityLive", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });       
      },
      changeR300LiveHandler(e) {
          this.$store.dispatch("rect/changeR300Live", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });  
        this.flowindexLive();     
      },
      changeR600LiveHandler(e) {
          this.$store.dispatch("rect/changeR600Live", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });   
        this.flowindexLive();     
      },

      flowindexLive(){        
        if(this.r300_Live != '' &&  this.r600_Live != '') { 
          this.flow_index_Live =  3.32 * Math.log(this.r600_Live / this.r300_Live)
          this.plastic_Viscosity_Live = this.r600_Live - this.r300_Live
          this.$store.dispatch("rect/changeFlowIndexLive", {
            displayId: this.properties.displayId,
            widgetId: this.properties.widgetId,
            value: this.flow_index_Live,
          });   
          this.$store.dispatch("rect/changePlasticViscosityLive", {
            displayId: this.properties.displayId,
            widgetId: this.properties.widgetId,
            value: this.plastic_Viscosity_Live,
          });     
          this.ConsistencyLive()
        }
        else{
          this.flow_index_Live = '';
        }
      },

      ConsistencyLive(){
        if(this.flow_index_Live != ''){
          this.Consistency_index = (this.r300/Math.pow(511, this.flow_index_Live))
          this.$store.dispatch("rect/changeConsistencyIndexLive", {
            displayId: this.properties.displayId,
            widgetId: this.properties.widgetId,
            value: this.Consistency_index_Live,
          });   
        }
        else{
          this.Consistency_index_Live = '';
        }
      },
      // REAL TIME DATA INPUT FIELDS FUNCTION
      change_real_time_input(e,field) {
        this.$store.dispatch("rect/swabSurgeRealTimedata", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
          field: field,
        });       
      },
      
      dark() {
        this.darkMode = true;
        this.$emit("dark");
      },
      light() {
        this.darkMode = false;
        this.$emit("light");
      },
      // -------  ADD ROW FUNCTION -------
      addRow: function () {
        this.rows.push({ pump: "", displacemnt: "" });
      },
      total_of_lengths(){
        if(this.trip_Depth){
          if(this.dc1_length && this.dc2_length && this.hwdp_length && this.dp_length){
            let total_length = parseFloat(this.dc1_length) + parseFloat(this.dc2_length) + parseFloat(this.hwdp_length) + parseFloat(this.dp_length)
            if(total_length !== parseFloat(this.trip_Depth)){
              let arr = ['DC1 LENGTH', 'DC2 LENGTH', 'HWDP LENGTH', 'DP LENGTH']
              this.$toast.clear()
              this.$toast.error(`Sum of "${arr}" should be equal to the "TRIP DEPTH"`,{duration: "2000", position: 'top', pauseOnHover: true})
            }
            else{
              this.$toast.clear()
            }
          }
        }
      }
    },
    beforeMount() {
      try{
        const wellDet = this.$store.state.disp.displays[this.displayId];
        if(wellDet){
          this.isWellMetrics = wellDet.unit_conversion;
        }
        let rects = this.$store.state.rect.rects;
        if (typeof rects != "undefined" && rects.length > 0) {
          for (let i = 0; i < rects.length; i++) {
            if (
              rects[i].widgetId == this.widgetId &&
              rects[i].displayId == this.displayId
            )
              this.idx = i;
          }
        }
        if(this.$store.state.rect.rects[this.idx].open_graph_properties == "tvdTrajectoryTrend3" + this.widgetId){
          this.staticGraphProp = false;
          this.realTimeGraphProp = true;
          this.realTimeGraphRanges = false;
          this.for_ranges_clicked_graph = ''; 
        }
        // else if(this.$store.state.rect.rects[this.idx].open_graph_properties == "tvdTrajectoryTrend3_ranges"){
        else if(this.$store.state.rect.rects[this.idx]?.open_graph_properties.includes('_ranges')){
          //realtime_graph_ranges
          //manual_graph1_ranges
          //manual_graph2_ranges
          this.staticGraphProp = false;
          this.realTimeGraphProp = false;
          this.realTimeGraphRanges = true;
          this.for_ranges_clicked_graph = this.$store.state.rect.rects[this.idx].open_graph_properties; 
          let data = this.$store.state.rect.rects[this.idx][this.for_ranges_clicked_graph];
          this.xAutoScale = data.xAutoScale;
          this.yAutoScale = data.yAutoScale;
          this.xRangeStart = data.xRangeStart;
          this.yRangeStart = data.yRangeStart;
          this.xRangeEnd = data.xRangeEnd;
          this.yRangeEnd = data.yRangeEnd;
          this.xAutoScalePpg = data.xAutoScalePpg;
          this.yAutoScalePpg = data.yAutoScalePpg;
          this.xRangeStartPpg = data.xRangeStartPpg;
          this.yRangeStartPpg = data.yRangeStartPpg;
          this.xRangeEndPpg = data.xRangeEndPpg;
          this.yRangeEndPpg = data.yRangeEndPpg;
        }
        else{
          this.staticGraphProp = true;
          this.realTimeGraphProp = false; 
          this.realTimeGraphRanges = false;
          this.for_ranges_clicked_graph = ''; 
        }  
      }
      catch(err){
        console.log(err)
      }
    },
    computed: {
      graph_name(){
        let graph = this.$store.state.rect.rects[this.idx].open_graph_properties;
        if(graph == 'realtime_graph_ranges'){
          return "Real time"; 
        }
        else if(graph == 'manual_graph1_ranges'){
          return "Manual graph 1" 
        }
        else if(graph == 'manual_graph2_ranges'){
          return "Manual graph 2" 
        }
        else{
          return "" 
        }
      },
      unitConversion() {
        return this.$store.state.rect.rects[this.idx].unitConversion || null;
      },
      // xAutoScale() {
      //   return this.$store.state.rect.rects[this.idx]?.xAutoScale || false;
      // },
      // yAutoScale() {
      //   return this.$store.state.rect.rects[this.idx]?.yAutoScale || false;
      // },
      // xRangeStart() {
      //   let val = this.$store.state.rect.rects[this.idx].xRangeStart
      //   return this.$store.state.rect.rects[this.idx].xRangeStart || val == 0 ? val : null;
      // },
      // yRangeStart() {
      //   let val = this.$store.state.rect.rects[this.idx].yRangeStart
      //   return this.$store.state.rect.rects[this.idx].yRangeStart || val == 0 ? val : null;
      // },
      // xRangeEnd() {
      //   let val = this.$store.state.rect.rects[this.idx].xRangeEnd
      //   return this.$store.state.rect.rects[this.idx].xRangeEnd || val == 0 ? val : null;
      // },
      // yRangeEnd() {
      //   let val = this.$store.state.rect.rects[this.idx].yRangeEnd
      //   return this.$store.state.rect.rects[this.idx].yRangeEnd || val == 0 ? val : null;
      // },
      // xAutoScalePpg() {
      //   return Object.keys(this.$store.state.rect.rects[this.idx]).includes('xAutoScalePpg') ? this.$store.state.rect.rects[this.idx].xAutoScalePpg : true;
      // },
      // yAutoScalePpg() {
      //   return this.$store.state.rect.rects[this.idx]?.yAutoScalePpg || false;
      // },
      // xRangeStartPpg() {
      //   let val = this.$store.state.rect.rects[this.idx].xRangeStartPpg
      //   return this.$store.state.rect.rects[this.idx].xRangeStartPpg || val == 0 ? val : null;
      // },
      // yRangeStartPpg() {
      //   let val = this.$store.state.rect.rects[this.idx].yRangeStartPpg
      //   return this.$store.state.rect.rects[this.idx].yRangeStartPpg || val == 0 ? val : null;
      // },
      // xRangeEndPpg() {
      //   let val = this.$store.state.rect.rects[this.idx].xRangeEndPpg
      //   return this.$store.state.rect.rects[this.idx].xRangeEndPpg || val == 0 ? val : null;
      // },
      // yRangeEndPpg() {
      //   let val = this.$store.state.rect.rects[this.idx].yRangeEndPpg
      //   return this.$store.state.rect.rects[this.idx].yRangeEndPpg || val == 0 ? val : null;
      // },
      darkDark() {
        return this.$store.state.rect.darkmode;
      },
      height_spot_column() {
        return this.$store.state.rect.rects[this.idx]?.height_spot_column || "";
      },  
      trip_Depth() {
        return this.$store.state.rect.rects[this.idx]?.trip_Depth || "";
      },
      trip_Depth_TVD() {
        return this.$store.state.rect.rects[this.idx]?.trip_Depth_TVD || "";
      },
      hole_size() {
        return this.$store.state.rect.rects[this.idx]?.hole_size || "";
      },
      dc1_od() {
        return this.$store.state.rect.rects[this.idx]?.dc1_od || "";
      },
      dc1_id() {
        return this.$store.state.rect.rects[this.idx]?.dc1_id || "";
      },
      dc1_length() {
        return this.$store.state.rect.rects[this.idx]?.dc1_length || "";
      },
      dc2_od() {
        return this.$store.state.rect.rects[this.idx]?.dc2_od || "";
      },
      dc2_id() {
        return this.$store.state.rect.rects[this.idx]?.dc2_id || "";
      },
      dc2_length() {
        return this.$store.state.rect.rects[this.idx]?.dc2_length || "";
      },
      hwdp_od() {
        return this.$store.state.rect.rects[this.idx]?.hwdp_od || "";
      },
      hwdp_id() {
        return this.$store.state.rect.rects[this.idx]?.hwdp_id || "";
      },
      hwdp_length() {
        return this.$store.state.rect.rects[this.idx]?.hwdp_length || "";
      },
      dp_od() {
        return this.$store.state.rect.rects[this.idx]?.dp_od || "";
      },
      dp_id() {
        return this.$store.state.rect.rects[this.idx]?.dp_id || "";
      },
      dp_length() {
        return this.$store.state.rect.rects[this.idx]?.dp_length || "";
      },
      static_casing_length() {
        return this.$store.state.rect.rects[this.idx]?.static_casing_length || "";
      },
      static_casing_id() {
        return this.$store.state.rect.rects[this.idx]?.static_casing_id || "";
      },
      mud_wght() {
        return this.$store.state.rect.rects[this.idx]?.mud_wght || "";
      },
      r300() {
        // this.flowindex()
        return this.$store.state.rect.rects[this.idx]?.r300 || "";
        
      },
      r600() {
        // this.flowindex()
        return this.$store.state.rect.rects[this.idx]?.r600 || "";
      },
      flow_index(){
        return this.$store.state.rect.rects[this.idx]?.flow_index || "";
      },
      Consistency_index(){
        return this.$store.state.rect.rects[this.idx]?.Consistency_index || "";
      },
      plastic_Viscosity(){
        return this.$store.state.rect.rects[this.idx]?.plastic_Viscosity || "";
      },
      yield_point(){
        return this.$store.state.rect.rects[this.idx]?.yield_point || "";
      },
      selected_tripmode() {
        return this.$store.state.rect.rects[this.idx]?.selected_tripmode || "";
      },
      selected_tripstatus() {
        return this.$store.state.rect.rects[this.idx]?.selected_tripstatus || "";
      },

      trip_Depth_Live() {
        return this.$store.state.rect.rects[this.idx]?.trip_Depth_Live || "";
      },
      trip_Depth_TVD_Live() {
        return this.$store.state.rect.rects[this.idx]?.trip_Depth_TVD_Live || "";
      },
      hole_size_Live() {
        return this.$store.state.rect.rects[this.idx]?.hole_size_Live || "";
      },
      dc1_od_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dc1_od_Live).toFixed(2) || "";
      },
      dc1_id_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dc1_id_Live).toFixed(2) || "";
      },
      dc1_length_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dc1_length_Live).toFixed(2) || "";
      },
      dc2_od_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dc2_od_Live).toFixed(2) || "";
      },
      dc2_id_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dc2_id_Live).toFixed(2) || "";
      },
      dc2_length_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dc2_length_Live).toFixed(2) || "";
      },
      hwdp_od_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.hwdp_od_Live).toFixed(2) || "";
      },
      hwdp_id_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.hwdp_id_Live).toFixed(2) || "";
      },
      hwdp_length_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.hwdp_length_Live).toFixed(2) || "";
      },
      dp_od_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dp_od_Live).toFixed(2) || "";
      },
      dp_id_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dp_id_Live).toFixed(2) || "";
      },
      dp_length_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dp_length_Live).toFixed(2) || "";
      },
      cassing_od() {
        return this.$store.state.rect.rects[this.idx]?.cassing_od || "";
      },
      cassing_id() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.cassing_id).toFixed(2) || "";
      },
      cassing_length() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.cassing_length).toFixed(2) || "";
      },
      mud_wght_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.mud_wght_Live).toFixed(2) || "";
      },
      r300_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.r300_Live).toFixed(2) || "";        
      },
      r600_Live() {
        return parseFloat(this.$store.state.rect.rects[this.idx]?.r600_Live).toFixed(2) || "";
      },
      selected_tripmode_Live() {
        return this.$store.state.rect.rects[this.idx]?.selected_tripmode_Live || "";
      },
      selected_tripstatus_Live() {
        return this.$store.state.rect.rects[this.idx]?.selected_tripstatus_Live || "";
      },
      dbtm_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dbtm_live).toFixed(2) || "";
      },
      trip_speed_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.trip_speed_live).toFixed(2) || "";
      },
      bit_size_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.bit_size_live).toFixed(2) || "";
      },
      dc1_casing_length_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dc1_casing_length_live).toFixed(2) || "";
      },
      dc1_open_hole_length_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dc1_open_hole_length_live).toFixed(2) || "";
      },
      dc2_casing_length_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dc2_casing_length_live).toFixed(2) || "";
      },
      dc2_open_hole_length_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dc2_casing_length_live).toFixed(2) || "";
      },
      dmea_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dmea_live).toFixed(2) || "";
      },
      dp_casing_length_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dp_casing_length_live).toFixed(2) || "";
      },
      dp_open_hole_length_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.dp_open_hole_length_live).toFixed(2) || "";
      },
      hwdp_casing_length_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.hwdp_casing_length_live).toFixed(2) || "";
      },
      hwdp_open_hole_length_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.hwdp_open_hole_length_live).toFixed(2) || "";
      },
      surge_pressure_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.surge_pressure_live).toFixed(2) || "";
      },
      surge_pressure_in_ppg_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.surge_pressure_in_ppg_live).toFixed(2) || "";
      },
      swab_pressure_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.swab_pressure_live).toFixed(2) || "";
      },
      swab_pressure_in_ppg_live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.swab_pressure_in_ppg_live).toFixed(2) || "";
      },
      flow_index_Live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.flow_index_Live).toFixed(2) || "";
      },
      Consistency_index_Live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.Consistency_index_Live).toFixed(2) || "";
      },
      plastic_Viscosity_Live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.plastic_Viscosity_Live).toFixed(2) || "";
      },
      yield_point_Live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.yield_point_Live).toFixed(2) || "";
      },
      trip_depth_tvd_Live(){
        return parseFloat(this.$store.state.rect.rects[this.idx]?.trip_depth_tvd_Live).toFixed(2) || "";
      },
      
    },
  };
  </script>
  
  <style scoped src="../../assets/styles/propstyle.css">
  
  </style>
  